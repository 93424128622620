import React from 'react';
import { styles } from '../styles';
const images = {
  phone: 'https://www.readmysubjectline.com/dev/iphone_5.png',
  quietcat: 'https://content.codecademy.com/courses/React/react_photo_quietcat.png'
}

export class SubjectLine1 extends React.Component {
  

    render(){
        const { valueSender, handleSender, valueSubject, handleSubject, handlePreHeader, valuePreHeader } = this.props;
        return (
    //this is input form #1
    <div >
      <div style={styles.div1Styles}>
       <p>Your Subject Line 2</p>
          <input type='text' onChange = {handleSender}
          placeHolder='hello is it me'
          style={styles.p1Styles}
          />
    
    <input type='text' onChange = {handleSubject}
          placeHolder='hello is it me'
          style={styles.p1Styles}
          />
    
    <input type='text' onChange = {handlePreHeader}
          placeHolder='hello is it me'
          style={styles.p1Styles}
          />     
    
      </div>
    
      <div style={styles.div2Styles}>
        <img
        style={styles.imgStyles}
        src = {images.phone}
        />
          <p style={styles.p2Styles}>{valueSender || 'Sender Name'}</p>
          <p style={styles.p3Styles}>{valueSubject || 'Subject Line'}</p>
         
          <p style={styles.p4Styles}>{valuePreHeader ||
           'This is the line for the pre-header line, please be concise otherwise your copy'}</p>
           
      </div>
    </div>
    
        )
      }
}

export default SubjectLine1;
