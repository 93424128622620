import React from 'react';
import ReactDOM from 'react';
import { SubjectLine1 } from '../components/App1'

const images = {
  copycat: 'https://content.codecademy.com/courses/React/react_photo_copycat.png',
  quietcat: 'https://content.codecademy.com/courses/React/react_photo_quietcat.png'
}

class SubjectContainer1 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          senderLine: true,
          input: '',
          input2: '',
          input3: ''
        }
        this.handleSender = this.handleSender.bind(this);
        this.handleSubject = this.handleSubject.bind(this);
        this.handlePreHeader = this.handlePreHeader.bind(this);
      };
      handleSender(e){
          this.setState({
              input: e.target.value
          })
      }
    
      handleSubject(e){
        this.setState({
            input2: e.target.value
        })
    }
    
    handlePreHeader(e){
        this.setState({
            input3: e.target.value
        })
    }
    
      render(){
          const valueSender = this.state.input;
          const handleSender = this.handleSender;
          const valueSubject = this.state.input2;
          const handleSubject = this.handleSubject;
          const valuePreHeader = this.state.input3;
          const handlePreHeader = this.handlePreHeader;     
        return (
    <SubjectLine1
    valueSender = {valueSender}
    handleSender = {handleSender}
    valueSubject = {valueSubject}
    handleSubject = {handleSubject}
    valuePreHeader = {valuePreHeader}
    handlePreHeader = {handlePreHeader}
    />
        )
      }
}

export default SubjectContainer1;