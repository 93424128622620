import React from 'react';
import ReactDOM from 'react';
import { SubjectLine } from '../components/App'

const images = {
  copycat: 'https://content.codecademy.com/courses/React/react_photo_copycat.png',
  quietcat: 'https://content.codecademy.com/courses/React/react_photo_quietcat.png'
}

class SubjectContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      senderLine: true,
      input: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: ''
    }
    this.handleSender = this.handleSender.bind(this);
    this.handleSubject = this.handleSubject.bind(this);
    this.handlePreHeader = this.handlePreHeader.bind(this);
    this.handleSender2 = this.handleSender2.bind(this);
    this.handleSubject2 = this.handleSubject2.bind(this);
    this.handlePreHeader2 = this.handlePreHeader2.bind(this);   
  };
  handleSender(e){
      this.setState({
          input: e.target.value
      })
  }
  handleSender2(e){
    this.setState({
        input4: e.target.value
    })
}

  handleSubject(e){
    this.setState({
        input2: e.target.value
    })
}

handleSubject2(e){
    this.setState({
        input5: e.target.value
    })
}

handlePreHeader(e){
    this.setState({
        input3: e.target.value
    })
}

handlePreHeader2(e){
    this.setState({
        input6: e.target.value
    })
}

  render(){
      const valueSender = this.state.input;
      const handleSender = this.handleSender;
      const valueSender2 = this.state.input4;
      const handleSender2 = this.handleSender2;     
      const valueSubject = this.state.input2;
      const handleSubject = this.handleSubject;
      const valueSubject2 = this.state.input5;
      const handleSubject2 = this.handleSubject2;      
      const valuePreHeader = this.state.input3;
      const handlePreHeader = this.handlePreHeader; 
      const valuePreHeader2 = this.state.input6;
      const handlePreHeader2 = this.handlePreHeader2;           
    return (
<SubjectLine
valueSender = {valueSender}
handleSender = {handleSender}
valueSender2 = {valueSender2}
handleSender2 = {handleSender2}
valueSubject = {valueSubject}
handleSubject = {handleSubject}
valueSubject2 = {valueSubject2}
handleSubject2 = {handleSubject2}
valuePreHeader = {valuePreHeader}
handlePreHeader = {handlePreHeader}
valuePreHeader2 = {valuePreHeader2}
handlePreHeader2 = {handlePreHeader2}
/>
    )
  }
}

export default SubjectContainer;