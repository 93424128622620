import React from 'react';
import { styles } from '../styles';
const images = {
  phone: 'https://www.readmysubjectline.com/dev/iphone_5.png',
  quietcat: 'https://content.codecademy.com/courses/React/react_photo_quietcat.png'
}

export class SubjectLine extends React.Component {
  

  render(){
    const { valueSender, valueSender2, handleSender, 
      handleSender2, valueSubject, valueSubject2, handleSubject, 
      handleSubject2, handlePreHeader, valuePreHeader, handlePreHeader2, valuePreHeader2 } = this.props;
    return (
//this is input form #1
<div style={styles.divStyles}>
  <div style={styles.div1Styles}>
   <p>Your Subject Line 1</p>
      <input type='text' onChange = {handleSender}
      placeHolder='Enter sender name'
      style={styles.p1Styles}
      />

<input type='text' onChange = {handleSubject}
      placeHolder='Enter subject line'
      style={styles.p1Styles}
      />

<input type='text' onChange = {handlePreHeader}
      placeHolder='Enter pre-header'
      style={styles.p1Styles}
      /> 
        


   <p>Your Subject Line 2</p>
      <input type='text' onChange = {handleSender2}
      placeHolder='Enter sender name'
      style={styles.p1Styles}
      />

<input type='text' onChange = {handleSubject2}
      placeHolder='Enter subject line 2'
      style={styles.p1Styles}
      />

<input type='text' onChange = {handlePreHeader2}
      placeHolder='Enter pre-header 2'
      style={styles.p1Styles}
      />        

  </div>

  <div style={styles.div2Styles}>
    <img
    style={styles.imgStyles}
    src = {images.phone}
    />
      <p style={styles.p2Styles}>{valueSender || 'Sender Name'}</p>
      <p style={styles.p3Styles}>{valueSubject || 'Subject Line'}</p>
     
      <p style={styles.p4Styles}>{valuePreHeader ||
       'This is the line for the pre-header line, please be concise otherwise your copy'}</p>
       
  </div>

  <div style={styles.div2Styles}>
    <img
    style={styles.imgStyles}
    src = {images.phone}
    />
      <p style={styles.p2Styles}>{valueSender2 || 'Sender Name 2'}</p>
      <p style={styles.p3Styles}>{valueSubject2 || 'Subject Line 2'}</p>
     
      <p style={styles.p4Styles}>{valuePreHeader2 ||
       'This is the line for the pre-header line, please be concise otherwise your copy'}</p>
       
  </div>
</div>

    )
  }
}

export default SubjectLine;










// import logo from '../logo.svg';
// import '../App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
