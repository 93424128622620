const fontFamily = 'Arial, Helvetica, sans-serif';
const fontSize = '2vh';
const backgroundColor = '#282c34';
const minHeight = '100vh';
const minWidth = 400;
const display = 'flex';
const alignItems = 'center';
const justifyContent = 'center';
const color = 'white';
const marginTop = '20px';
const width = '50%';
const flexDirection = 'column';

const divStyles = {
  fontFamily: fontFamily,
  fontSize: fontSize,
  color: color,
  backgroundColor: backgroundColor,
  

};

const div1Styles = {
    fontFamily: fontFamily,
    fontSize: fontSize,
    color: color,
    backgroundColor: '#999',
    float: 'left',
    border: '3px solid #000',
    padding: 10,
    width: 300

  };


  const p1Styles = {
        marginTop: 0
  }

  const p2Styles = {

    zIndex: 12, 
    color: '#333', 
    marginTop: -648, 
    backgroundColor: 'white', 
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: 70, 
    paddingRight: 32, 
    height: 23, 
    inlineSize: 260, 
    whiteSpace: 'pre-wrap', 
    overflow: 'hidden'
  }

  const p3Styles = {
    zIndex: 13, 
    color: '#222', 
    marginTop: -17, 
    backgroundColor: 'white', 
    fontSize: 15.5,
    paddingLeft: 70, 
    paddingRight: 32, 
    height: 23, 
    inlineSize: 260, 
    whiteSpace: 'pre-wrap', 
    overflow: 'hidden',
    fontFamily: 'arial'
  }

  const p4Styles = {
    zIndex: 13, 
    color: 'grey', 
    marginTop: -17, 
    backgroundColor: 'white', 
    fontSize: 15.5,
    paddingLeft: 70, 
    paddingRight: 32, 
    height: 23, 
    inlineSize: 270, 
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word'
  }

  const elPre = {
        zIndex: 16,
        backgroundColor: 'blue',
        height: 100,
        marginTop: -10
  }


  const div2Styles = {
    fontFamily: fontFamily,
    fontSize: fontSize,
    color: color,
    backgroundColor: 'white',
    float: 'left',
    border: '0px solid #000',
    width: 400,
    
  };

const imgStyles = {
  marginTop: marginTop,
  width: 400,
  zIndex: 13
};

export const styles = {
  divStyles: divStyles,
  div1Styles: div1Styles,
  div2Styles: div2Styles,  
  imgStyles: imgStyles,
  p1Styles: p1Styles,
  p2Styles: p2Styles,
  p3Styles: p3Styles,
  p4Styles: p4Styles,
  p5Styles: p4Styles,
  elPre: elPre
}